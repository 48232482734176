import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';
import Unsplash from "./../../client_customizations/components/PageLayout/Unsplash/Unsplash";
import unsplashImage from "./../../client_customizations/assets/images/unsplash-burbank-sign.jpg";
import getFilteredData from "../../utils/Helpers/getFilteredData";

import "./Incentives.scss";

const Incentives = ({ electricVehicles, incentives, ip, uuid }) => {

  incentives = getFilteredData([
    { field: "grantor_type", value: "Power Supplier", count: 100 },
    { field: "grantor", value: "Federal", count: 100 },
    { field: "grantor_type", value: "State", count: 100 },
  ], incentives)

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container incentives-page">
        <div className="row">
          <div className="col-sm-12 col-md-6 md-offset-3 IncentiveText text-center">
            <h1>
              <FormattedMessage 
                id="incentives.title"
                defaultMessage="Electric Vehicle Incentives"
                description="Electric Vehicle Incentives"
              />
            </h1>
            <p className="lead">
              <FormattedMessage 
                id="incentives.subTitle"
                defaultMessage="You may be eligible for incentives including EV rebates, tax credits, and other benefits from BWP and other agencies."
                description="Incentives Subtitle"
              />
            </p>
            <IncentivePreferences
              incentives={incentives}
              electricVehicles={electricVehicles}
              titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
              btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
            />
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
      </section>
      <Unsplash
          img={unsplashImage}
        />

    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
