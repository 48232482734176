import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { 
  Collapse, 
  Navbar, 
  NavbarToggler, 
  Nav, 
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { EV_COMPARE } from "../../../../constants/evCompare";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import {FormattedMessage} from 'react-intl';

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);    

  const pageNavProps = Object.keys(EV_COMPARE);
  const baseElectricVehicles = "Electric Vehicles";

  const checkEVComparePage = (page) => {
    return pageNavProps.indexOf(page) > -1;
  }

  const getEVPageNav = (page) => {
    return checkEVComparePage(page) ? EV_COMPARE[page].tabName : baseElectricVehicles;
  }

  return (
    <div className="Header">
      <div style={{ 
          paddingLeft: "2.5rem",
          paddingRight: "5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem"
        }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="/home"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "255px" }}
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
                <UncontrolledDropdown
                  nav
                  inNavbar  
                >
                  {/* TODO find way to use different caret in dropdowntoggle */}
                  <DropdownToggle
                    nav
                    caret
                    className={checkEVComparePage(page) ? 'active' : null}
                  >
                    <span>
                      <FormattedMessage 
                        id="header.electricVehicles"
                        defaultMessage="ElectrAc Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>

                  </DropdownToggle>
                  <DropdownMenu
                    right 
                  >
                    {pageNavProps.map((navURL) => {
                        const tab = EV_COMPARE[navURL];;

                        return (
                          <DropdownItem
                            key={`nav-item-${tab.id}`}
                            title={getEVPageNav(page)}
                            to={`/${navURL}`}
                            tag={NavLink}
                            role="menuitem"
                          >
                            <span>
                              <FormattedMessage 
                                id={tab.id}
                                defaultMessage={tab.tabName}
                                description={`${tab.tabName} Menu Item`}
                              />
                            </span>
                          </DropdownItem>
                        );
                      })
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>              
              ):(null)}              
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage 
                      id="incentives"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
              { process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                <NavItem>
                <NavLink
                  title={page === "charging-stations" ? "Active Page" : null}
                  to="/charging-stations"
                >
                  <span>
                    <FormattedMessage 
                      id="chargingStations"
                      defaultMessage="Charging Stations"
                      description="Charging Stations Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
              { process.env.REACT_APP_PAGES_HOME_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === "home-chargers" ? "Active Page" : null}
                  to="/home-chargers"
                >
                  <span>                    
                    <FormattedMessage 
                      id="homeChargers"
                      defaultMessage="Home Chargers"
                      description="Home Chargers Header"
                    />
                  </span>
                </NavLink>
              </NavItem> 
              ):(null)}
              { process.env.REACT_APP_PAGES_DEALERS_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === "dealers" ? "Active Page" : null}
                  to="/dealers"
                >
                  <span>                    
                    <FormattedMessage 
                      id="dealers"
                      defaultMessage="Dealers"
                      description="Dealers Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};