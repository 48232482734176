import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import isHomepage from "../../../utils/isHomepage";
import iconIncentiveZap from "../../assets/images/icons/icon-incentive-zap.svg";

const IncentiveCard = props => {
  let cardBodyClass = isHomepage() ? 'HomeIncentiveCardBody' : 'IncentiveCardBody';
  const incentiveZapImg = (
    <img 
      src={iconIncentiveZap}
      alt="dollar sign icon"
      className="incentive-zap-icon"
    />
  );

  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }

  if(incentive.grantor_type === 'Power Supplier') {
    cardBodyClass = `${cardBodyClass} BWPIncentinveCardBody`;
  }

  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : incentiveZapImg;

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;



  let renderCard = incentive ? (
    <div className="col-lg-3 col-md-4 col-sm-6">
      <a
        className="IncentiveCard"
        href={incentive.details_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={`${cardBodyClass} incentive-card-body`}>
          <p className="incentive-type">{incentive.type}</p>
          <p className="h1 card-title">
            {amount ? FormatAsDollars(amount) : amountDescription}
          </p>
          <div className="card-name-container">
            <p className="h6">{name}</p>
          </div>
        </div>
        <div className="IncentiveCardBottom">
          <div className="pl-5 pr-5">
            <hr />
          </div>
          <p>
            {description}
          </p>
        </div>
      </a>
    </div>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
