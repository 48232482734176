import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "./../../components/EVIncentives/EVIncentives";
import EVJumbotron from "./../../components/EVJumbotron/EVJumbotron";
import EVReviews from "./../../components/EVReviews/EVReviews";
import ChargingMap from "./../../components/LocationMap/ChargingMap";
import CostOfOwnership from "./../../components/CostOfOwnership/CostOfOwnership";
import getPaymentDetails from "./../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormatCarName } from "../../utils/Helpers/Format";

import Unsplash from "./../../client_customizations/components/PageLayout/Unsplash/Unsplash";
import unsplashImage from "./../../client_customizations/assets/images/unsplash-tesla-parking.jpg";

import "./EV.scss";

const EV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid
}) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (electricVehicles) {
    const evId = match ? match.params["evId"] : 0;
    car = electricVehicles.find(ev => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax")
    );
  }

  useEffect(() => {
    document.title = car
      ? `${FormatCarName(car)}`
      : "";
  });

  const ignoreSuperchargerStations = car && isPHEV(car);

  const renderEV = electricVehicles ? (
    <>
      <EVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />
      <CostOfOwnership cars={[car, car.equivalent_gas_vehicle]} />
      <EVIncentives incentives={car.incentives} car={car} />
      <section className="container" id="ev-charging-map">
        <ChargingMap
          userLocation={userLocation}
          ignoreSuperchargerStations={ignoreSuperchargerStations}
        />
      </section>
      <EVReviews reviews={car.video_reviews} />
      <Unsplash
          img={unsplashImage}
      />
    </>
  ) : (
      <div className="container text-center">
        <LoadingSpinner />
      </div>
    );


  return (
    <>
     { renderEV }
    </>
  );
};

export default EV;

EV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
