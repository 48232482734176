import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../LinkCard/LinkCard";

import {FormattedMessage} from 'react-intl';

const HomepageBigPromise = ({
  homePageBannerImage
}) => {

  const welcomeFuture = "Welcome to the Future";
  const electrifyDrive = "Electrify your drive with {lineBreak}Burbank Water &amp; Power";


  return (
    <section className="p-0">
      <div 
          className="big-promise" 
          style={{ 
            position: "relative"
          }
      }>
        <div
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "770px",
            boxSizing: "border-box",
            width: "100%"
          }}
        >
        </div>
        <div id="welcome-to-the-future">
            <div className="link-card-container">
              <h1>          
                <FormattedMessage 
                  id="homepage.welcome"
                  defaultMessage={welcomeFuture}
                  values= {{
                    lineBreak: <br />
                  }}
                />        
              </h1>
            </div>
            <div className="link-card-container">
              <h2 className="normalFont">
                <FormattedMessage 
                    id="homepage.welcomeSub"
                    defaultMessage={electrifyDrive}
                    values= {{
                      lineBreak: <br />
                    }}
                />
            </h2>               
          </div> 
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="LOCATE_CHARGING_STATIONS" />
              <LinkCard type="HOME_CHARGERS" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}