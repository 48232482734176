import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// import FuelRangeChart from "../../../components/CostsCharts/FuelRangeChart";
// import MonthlyFuelCostChart from "../../../components/CostsCharts/MonthlyFuelCostChart";
// import CostToOwnComparison from "../../../components/CostsCharts/CostToOwnComparison";
import {FormattedMessage} from 'react-intl';

import EVCard from "../../../components/EVCard/EVCard";

const VehicleCarousel = ({ electricVehicles }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
          hasEvDetails={true}
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  /*
  const renderVehicleCostChart = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      <FuelRangeChart car={electricVehicles[evIndices[selectedEv]]} />
    </div>
  );
  */


  /*
  const renderMonthlyFuelCostChart = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      <MonthlyFuelCostChart car={electricVehicles[evIndices[selectedEv]]} />
    </div>
  );
  */
  
  /*
  const renderCostToOwnComparison = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
    <CostToOwnComparison
        car={electricVehicles[evIndices[selectedEv]]}
        hasExtraPadding
        displayEvCostBreakdownOption
        forceUserPrefsPresets
      />
     </div>
  );
  */

  return (
    <section className="container pb-0 HomepageVehiclesCarousel" id="HomepageVehiclesCarousel">
      <div className="row carousel-header">
        <div className="col-xs-12 col-md-12">
          <h2>
            <FormattedMessage 
                id="homepage.vehicleCarousel.title"
                defaultMessage="There are {vehicleCount} electric vehicles available. Discover yours."
                description="Vehicle Carousel Title"
                values= {{
                  vehicleCount: vehicleCount
                }}
              />
          </h2>          
      </div> 
      <div className="row carousel-header container">   
          <div className="col-md-10 col-xs-12 offset-md-1 carousel-header-para">
            <FormattedMessage 
              id="homepage.vehicleCarousel.subTitle"
              defaultMessage="Shopping for a new vehicle can be a complicated process, let alone switching from a gasoline-powered car to an electric one. BWP's here to make shopping for an EV easier. "
            />
          </div>
          <div className="col-md-10 col-xs-12 offset-md-1 carousel-header-para">
            <FormattedMessage 
              id="homepage.vehicleCarousel.subTitle-2"
              defaultMessage="Our EV buyer's guide helps you find the right EVs for your needs, takes the guesswork out of charging and gives you access to rebates."
              description="Vehicle Carousel Title-2"
            />
          </div>
        </div>
      </div>  
      <div className="row carousel-header">
        <div className="col-md-12 col-xs-12 find-ev-button">
          <Link to="/vehicles" className="btn btn-ae" role="button">
            <FormattedMessage 
              id="homepage.vehicleCarousel.findEVButton"
              defaultMessage="Find Your EV"
              description="Find EV Button"
            />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
