import React from "react";
import PropTypes from "prop-types";

import IncentivePreferences from "./../IncentivesPreferences/IncentivePreferences";

import SlideMilesDrivenDaily from "../InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../InputComponents/SlideMinSeats/SlideMinSeats";
import SelectChargerLevel from "../InputComponents/SelectChargerLevel/SelectChargerLevel";

import "./MatchScoreOptions.scss"

import {FormattedMessage, useIntl} from 'react-intl';
import SlideGasolinePrice from "../InputComponents/SlideGasolinePrice/SlideGasolinePrice";

const MatchScoreOptions = ({ electricVehicles }) => {
  const intl = useIntl();
  
  return (
    <div className="input-well MatchScoreOptions">
      <p className="h2">
        <FormattedMessage 
          id="evs.matchScoreOptions"
          defaultMessage="Refine Match Score"
          description="Refine Match Score"
        />
      </p>
      <SlideMilesDrivenDaily 
        label={intl.formatMessage ? intl.formatMessage({ id: "evs.roundTripCommute", defaultMessage: "Roundtrip Commute"}) : "Roundtrip Commute"}
      />

      <SlideMaxBudget
          label={intl.formatMessage ? intl.formatMessage({ id: "evs.budgetAfterIncentives", defaultMessage: "Budget After Incentives"}) : "Budget After Incentives"}
      />
      <IncentivePreferences
        electricVehicles={electricVehicles}
        titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
        btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
        btnSubtle
      />

      <div style={{ height: "1rem" }} />

      <SlideMinSeats 
        label={intl.formatMessage ? intl.formatMessage({ id: "evs.minSeats", defaultMessage: "Minimum Seats"}) : "Minimum Seats"}
      />
      <SelectChargerLevel />

      <div style={{ height: "1rem" }} />
      
      <SlideGasolinePrice />
    </div>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array
};
