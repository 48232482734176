import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import TwitterIcon from "../../../assets/images/icons/Twitter.png";
import FacebookIcon from "../../../assets/images/icons/Facebook.png";
import YouTubeIcon from "../../../assets/images/icons/YouTube.png";
import LinkedInIcon from "../../../assets/images/icons/LinkedIn.png";
import SmoothScroll from "../../../../utils/Helpers/SmoothScroll";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

// import en from "../../../translations/en";

import burbankIcon from "../../../assets/images/burbank-white.svg";

import {FormattedMessage} from 'react-intl';

const Footer = ({language, changeLanguage}) => {

  const languageOptions = () => {
    return (
      <>
        <option
          value={'ES'}
          key={'ES'}
        >
          {'Translate to Spanish'}
        </option>
      </>
    )
  }

  const onChangeLanguage = e => {
    if(typeof changeLanguage === 'function') {
      changeLanguage(e.target.value);
      SmoothScroll("root");
    }
  }

  const copyright = (
    <div className="footer-copyright">
      &copy;	                
        {(new Date().getFullYear().toString()) } {`${process.env.REACT_APP_FULL_COMPANY_NAME}. `}
        <FormattedMessage
          id="footer.allRights" 	                            
          defaultMessage=" All Rights Reserved."	                      
          description="All Rights Reserved"
        />
    </div>
  );

  const zappyCopyright = (
    <div className="footer-zappycopyright">
      <span> &copy;
            {(new Date().getFullYear().toString()) } J.D. Power ZappyRide(c). {" "}
            <a style={{fontWeight: 300, fontSize: 12}} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
      </span>
    </div>
  );

  const langAndCopyright = (
    <div>
      <select	
        id="langSelector"
        aria-label="Select language"
        className="select-dte"	
        value={language}	
        onChange={onChangeLanguage}	
      >	
        <option defaultValue="EN" value={'EN'}>	
          English	
        </option>	
        {languageOptions()}	
      </select>
      {copyright}
      {zappyCopyright}
    </div>
  );

  const socialIcons = (
    <div className="social-icons mb-2">
      <div>
        <a
          href="https://twitter.com/burbankh2opower"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img className="social-media-icon" alt="Twitter Icon" src={TwitterIcon}/>
        </a>
        <a
          href="https://www.linkedin.com/company/burbank-water-&-power"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img className="social-media-icon" alt="LinkedIn Icon" src={LinkedInIcon}/>
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/BurbankWaterAndPower"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img className="social-media-icon" alt="Facebook Icon" src={FacebookIcon}/>
        </a>
        <a
          href="https://www.youtube.com/user/TheBurbankChannel/live"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img className="social-media-icon" alt="YouTube Icon" src={YouTubeIcon}/>
        </a>
      </div>
    </div>
  );

  return (
    <div className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row footer-row">
          <div className="col-6 col-md-1">
            <img 
              src={burbankIcon}
              className="img-fluid footer-icon"
              alt="City of Burbank Water and Power"
              style={{ minWidth: "98px"}}/>
          </div>
          <div className="footerLinks footerLinks col-6 col-md-2 pl-4">
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles" className="footerLink">
                <FormattedMessage 
                  id="newVehicles"
                  defaultMessage="New Vehicles"
                  description="New Electric Vehicles Footer"
                  values= {{
                    electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles" className="footerLink">
                <FormattedMessage 
                  id="usedElectricVehicles"
                  defaultMessage="Used Vehicles"
                  description="Used Electric Vehicles Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles" className="footerLink">
                <FormattedMessage 
                  id="compareVehicles"
                  defaultMessage="Compare Vehicles"
                  description="Compare Vehicles Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="footerLinks footerLinks-col-2 col-6 col-md-2 pl-4">
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link to="/charging-stations" className="footerLink">
                <FormattedMessage 
                  id="chargingStations"
                  defaultMessage="Charging Stations"
                  description="Charging Stations Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_HOME_ENABLED ? (<>
              <Link to="/home-chargers" className="footerLink">
                <FormattedMessage 
                  id="homeChargers"
                  defaultMessage="Home Chargers"
                  description="Home Chargers Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>    
          <div className="footerLinks footerLinks-col-3 col-6 col-md-1 pl-4">
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link to="/incentives" className="footerLink">
                <FormattedMessage 
                  id="incentives"
                  defaultMessage="Incentives"
                  description="Incentives Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link to="/dealers" className="footerLink">
                <FormattedMessage 
                  id="dealers"
                  defaultMessage="Dealers"
                  description="Dealers Footer"
                  values= {{
                    incentives: process.env.REACT_APP_PAGES_DEALERS_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>    
          <div id="social" className="col-12 col-md-2">
            {socialIcons}
          </div>
          <div id="translate" className="col-12 col-md-4">
            {langAndCopyright}
          </div>
        </div>
        <div className="row legal-disclaimer">
        <div className="disclaimer-container">
              <DisclaimerComponent clientName="zappynobackground" />
       </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
