import React from 'react';
import './unsplash.scss';

const Unsplash = props => {
    const unsplashDiv = (
          <div
            className="unsplash"
            style={{
              backgroundImage: "url(" + props.img + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              WebkitBackgroundSize: "cover",
              MozBackgroundSize: "cover",
              OBackgroundSize: "cover",
              backgroundSize: "cover",
              padding: 0,
              textAlign: "center",
              height: "297px",
              boxSizing: "border-box",
              margin: "0 auto",
            }}
          >
          </div>
    );
    return unsplashDiv;
}

export default Unsplash;