import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData";
import IncentiveCatalog from "../../IncentiveCatalog/IncentiveCatalog"
import {FormattedMessage} from 'react-intl';
import incentivesBannerImage from "../../../assets/images/homepage-banner-incentives.jpg"

const HomepageIncentives = ({ 
  incentives
 }) => {
  incentives = getFilteredData([
    { field: "grantor_type", value: "Power Supplier", count: 10 },
    { field: "grantor", value: "Federal", count: 2 },
    { field: "grantor_type", value: "State", count: 4 },
  ], incentives)

  return (
    <section 
      className="pb-0" id="HomepageIncentives"
      style={{
        textAlign: "center",
        backgroundImage: "url(" + incentivesBannerImage + ")",
        backgroundColor: 'rgba(0,0,0,0.3)',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
        padding: 0,
        boxSizing: "border-box",
        width: "100%"
      }}
    >
      <h2>
        <FormattedMessage 
            id="homepage.incentives.title"
            defaultMessage="Explore potential EV incentives and tax credits"
            description="Homepage Incentives Title"
        />
      </h2>
      <p className="lead">
        <FormattedMessage 
            id="homepage.incentives.subTitle"
            defaultMessage="See how much you could save getting behind the wheel of an EV, whether you are {linebreak} buying or leasing. Incentives are personalized for Burbank residents."
            description="Homepage Incentives Sub Title"
          />
      </p>
      <Link to="/incentives" style={{margin:"12px"}} className="btn btn-ae" role="button">
        <FormattedMessage 
            id="homepage.incentives.exporeIncentives"
            defaultMessage="Find Incentives"
            description="Explore Incentives Button"
          />
      </Link>
      <div className="homepage-incentive-catalog">          
        { /* @TODO different limits for different sizes */}
        <IncentiveCatalog 
          incentives={incentives} 
          category="all" 
          limit={4}
        />
      </div>
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
