import React from "react";
import PropTypes from "prop-types";
import ChargerCard from "../ChargerCard/ChargerCard"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const ChargerCatalog = ({
  homeChargers, 
  selectedVehicle,
  incentivePricing,
  setCostBreakdown = () => {},
  setSelectedCharger = () => {}
}) => {
  if (!homeChargers) return <LoadingSpinner />;

  if (!homeChargers.length) {
    return (
      <div className="text-center">
        <p className="h4">No Chargers found</p>
      </div>
    );
  }

  const renderCards = homeChargers.map((charger, index) => {
    return (
        <div key={index}className="evc-card ChargerCard">
            <ChargerCard
              charger={charger}
              selectedVehicle={selectedVehicle}
              incentivePricing={incentivePricing}
              setCostBreakdown={setCostBreakdown}
              setSelectedCharger={setSelectedCharger}
            />
        </div>
    );
  });

  return <div className="render-cards-container">{renderCards}</div>;
};

export default ChargerCatalog;

ChargerCatalog.propTypes = {
    homeChargers: PropTypes.array,
    selectedVehicle: PropTypes.object,
    incentivePricing: PropTypes.bool,
    setCostBreakdown: PropTypes.func,
    setSelectedCharger: PropTypes.func
};
