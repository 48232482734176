import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from 'react-intl';
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../hooks/useMapTabs';

import Unsplash from "./../../client_customizations/components/PageLayout/Unsplash/Unsplash";
import chargingStationUnsplashImage from "./../../client_customizations/assets/images/unsplash-parkinglot-hisphone.jpg";
import dealersUnsplashImage from "./../../client_customizations/assets/images/unsplash-parkinglot-herphone.jpg";

const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode, h1Id, leadId }) => {
  useEffect(() => {
    document.title = title;
  });

  const { toggle, findTab } = useMapTabs(tabId);

  const tabIDToUnsplash = {
    'tabbed-maps-all-stations-tab' : chargingStationUnsplashImage,
    'tabbed-maps-dealer-catalog-tab':  dealersUnsplashImage   
  }

  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <>
      <section className="container">
        <h1 className="hide-offscreen">Map</h1>
        <section className="container">
          <h1 className="text-center">
            <FormattedMessage 
              id={h1Id}
              defaultMessage={h1Id}
              values= {{
                lineBreak: <br />
              }}
            />        
          </h1>

          <p className="lead text-center">

            <FormattedMessage 
              id={leadId}
              defaultMessage={leadId}
              values= {{
                lineBreak: <br />
              }}
            />        

          </p>
        </section>
        <TabbedMaps
          toggle={toggleWithHistory}
          activeTabId={tabId}
          userLocation={userLocation}
          dealerLocations={dealerLocations}
          zipcode={zipcode}
        />
      </section>
      <Unsplash
        img={
          tabIDToUnsplash[tabId]
        }
      />
    </>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
