import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";

const CarGallery = ({ carImages }) => {
  const [activeImg, setActiveImg] = useState(0);

  const RenderThumbnails = () => {
    return carImages.map((carImage, index) => {
      return (
        <div className="col" key={index}>
          <NavLink
            className={activeImg === index ? "active" : ""}
            onClick={() => setActiveImg(index)}
            onKeyPress={() => setActiveImg(index)}
            tabIndex="0"
            style={{ cursor: "pointer", padding: 5, borderRadius: 4 }}
          >
            <img
              src={carImage.thumbnail}
              className="img-fluid"
              alt={carImage.originalAlt || 'Vehicle Image'}
              style={{ borderRadius: "4px" }}
            />
          </NavLink>
        </div>
      );
    });
  };

  const RenderSelectedImage = () => {
    return carImages.map((carImage, index) => {
      return (
        <TabPane tabId={index} key={index}>
          <div className="row">
            <div className="col" style={{ padding: 10 }}>
              <img
                src={carImage.original}
                className="img-fluid"
                alt={carImage.originalAlt || "Vehicle Image"}
              />
            </div>
          </div>
        </TabPane>
      );
    });
  };

  return (
    <>
      <TabContent activeTab={activeImg}>
        <RenderSelectedImage />
      </TabContent>
      <Nav className="nav-fill">
        <li
            className="row"
            style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
          }}
        >
          <RenderThumbnails />
        </li>
      </Nav>
    </>
  );
};

export default CarGallery;

CarGallery.propTypes = {
  carImages: PropTypes.array
};
