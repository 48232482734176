import React from "react";
import { useIntl } from 'react-intl';

import './askBWP.scss';

const AskBWPButton = () => {
    const intl = useIntl();

    return(
        <a 
            href="https://burbankwaterandpower.com/electric-vehicle-questions"
            target="_blank"
            rel="noopener noreferrer"
        >
            <button className="btn btn-ae askBWP"> {
                intl.formatMessage
                ? intl.formatMessage({ id: "askBWP", defaultMessage: "ASK BWP"})
                : 'ASK BWP'
            } </button>
        </a>
    )
}

export default AskBWPButton;