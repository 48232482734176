import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../assets/images/icons/browse-electric-vehicles-no-hover.svg";
import DISCOVER_INCENTIVES from "../../assets/images/icons/discover-incentives-no-hover.svg";
import LOCATE_CHARGING_STATIONS from "../../assets/images/icons/locate-charging-stations-no-hover.svg";
import HOME_CHARGERS from "../../assets/images/icons/home-chargers-big-promise-no-hover.svg";
import BROWSE_VEHICLES_HOVER from "../../assets/images/icons/browse-electric-vehicles.svg";
import DISCOVER_INCENTIVES_HOVER from "../../assets/images/icons/discover-incentives.svg";
import LOCATE_CHARGING_STATIONS_HOVER from "../../assets/images/icons/locate-charging-stations.svg";
import HOME_CHARGERS_HOVER from "../../assets/images/icons/home-chargers-big-promise.svg"

import { Link } from "react-router-dom";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type }) => {
  let src, srcHover, link, scrollTo, title;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      srcHover = BROWSE_VEHICLES_HOVER;
      scrollTo = "HomepageVehiclesCarousel";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="Browse Electric {lineBreak} Vehicles"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />        
        </span>
      );
      break;
    case "DISCOVER_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      srcHover = DISCOVER_INCENTIVES_HOVER;
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="Discover {lineBreak} Incentives"
            description="HomePage Linkcard Discover Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      srcHover = LOCATE_CHARGING_STATIONS_HOVER;
      link = "/charging-stations";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="Locate Charging {lineBreak} Stations"
            description="HomePage Linkcard Locate Charging Stations"
            values= {{
              lineBreak: <br />
            }}
          />        
          </span>
      );
      break;
    case "HOME_CHARGERS":
      src = HOME_CHARGERS;
      srcHover = HOME_CHARGERS_HOVER;
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.homeChargers"
            defaultMessage="Discover Home {lineBreak} Chargers"
            description="HomePage Linkcard Home Chargers"
            values= {{
              lineBreak: <br />
            }}
          />        
        </span>
      );
      break;
    default:
      return null;
  }

  const icon = (
    <div className='icon-frame icon-frame-no-hover'>
      <img src={src} alt="" />
    </div>
  );

  const iconHover = (
    <div className='icon-frame icon-frame-hover'>
      <img src={srcHover} alt="" />
    </div>
  )

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card opaque"
        onClick={e => SmoothScroll(scrollTo)}
      >
        <div>
          {icon}
          {iconHover}
          <div className="titleFrame">
            <p className="h6">{title}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="evc-card opaque">
        <div>
          {icon}
          {iconHover}
          <div className="titleFrame">
            <p className="h6">{title}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};