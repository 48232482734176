export const EV_COMPARE = {
    'vehicles': {
        id: 'newVehicles',
        tabName: 'New Vehicles'
    },
    'used-vehicles': {
        id: 'usedElectricVehicles',
        tabName: 'Used Vehicles'
    },
    'compare-vehicles': {
        id: 'compareVehicles',
        tabName: 'Compare New Vehicles'
    }
}
