const ALL_STATIONS_TAB_NAME = "All Stations";
const ALL_STATIONS_TAB_URL = "/charging-stations";
export const ALL_STATIONS_TAB_ID = "tabbed-maps-all-stations-tab";

const DEALER_CATALOG_TAB_NAME = "Dealer Catalog";
const DEALER_CATALOG_TAB_URL = "/dealers";
export const DEALER_CATALOG_TAB_ID = "tabbed-maps-dealer-catalog-tab";

const ELECTRICIANS_TAB_NAME = "Electricians";
const ELECTRICIANS_TAB_URL = "/electricians";
export const ELECTRICIANS_TAB_ID = "tabbed-maps-electricians-tab";

const ROUTE_MAP_TAB_NAME = "Map a Route";
const ROUTE_MAP_TAB_URL = "/map-a-route";
export const ROUTE_MAP_TAB_ID = "tabbed-maps-route-tab";

export const MAP_TABS = [
  {
    id: ALL_STATIONS_TAB_ID,
    name: ALL_STATIONS_TAB_NAME,
    url: ALL_STATIONS_TAB_URL,
    title: process.env.REACT_APP_PAGES_CHARGING_TITLE,
    h1Id: "locateChargingStations",
    leadId: "burbankChargingStations",
    description: "Find local public charging stations",
    isEnabled: process.env.REACT_APP_PAGES_CHARGING_ENABLED
  },
  {
    id: ROUTE_MAP_TAB_ID,
    name: ROUTE_MAP_TAB_NAME,
    url: ROUTE_MAP_TAB_URL,
    title: process.env.REACT_APP_PAGES_MAP_ROUTE_TITLE,
    h1Id: "locateChargingStations",
    leadId: "burbankChargingStations",
    description: "Map a route",
    isEnabled: process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED
  },
  {
    id: DEALER_CATALOG_TAB_ID,
    name: DEALER_CATALOG_TAB_NAME,
    url: DEALER_CATALOG_TAB_URL,
    title: process.env.REACT_APP_PAGES_DEALERS_TITLE,
    h1Id: "dealersTitle",
    leadId: "dealersSubTitle",
    description: "Find OEM certified Electric Vehicle (EV) dealers",
    isEnabled: process.env.REACT_APP_PAGES_DEALERS_ENABLED
  },
  {
    id: ELECTRICIANS_TAB_ID,
    name: ELECTRICIANS_TAB_NAME,
    url: ELECTRICIANS_TAB_URL,
    title: process.env.REACT_APP_PAGES_ELECTRICIANS_TITLE,
    h1Id: "locateChargingStations",
    leadId: "burbankChargingStations",
    description:  "Find Electricians",
    isEnabled: process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED
  }
];
