import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
// import "./HomeChargers.scss";
import Uuid from "../../utils/Uuid/Uuid"
import ChargerCatalog from "../../components/ChargerCatalog/ChargerCatalog"
import ChargerSortControls from "./../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "./../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import ProductIncentiveCounter from "../../components/shared/Counters/ProductIncentiveCounter";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import filterThenSortChargers from "../../functions/chargers/filterThenSortChargers";
import { FormattedMessage, useIntl } from 'react-intl';
import Unsplash from "./../../client_customizations/components/PageLayout/Unsplash/Unsplash";
import unsplashImage from "./../../client_customizations/assets/images/unsplash-zappy-fuel-red.jpg";

import "./HomeChargers.scss";

const vehicleMakeOptions = (vehicles) => {

  let vehicleMakes = new Set()
  vehicles.forEach(vehicle => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make)
    }
  })

  vehicleMakes = [...vehicleMakes]
  
  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b)
  })

  return vehicleMakes.map(make => {
    return (
      <option
        value={make}
        key={make}
      >
        {make}
      </option>
    )
  })
}

const vehicleModelOptions = (vehicles, makeOption) => {

  if (makeOption) {
    vehicles = vehicles.filter(vehicle => {
      return vehicle.make === makeOption
    })
  
  vehicles.sort((a, b) => {
    return a.model.localeCompare(b.model)
  })
    
    return vehicles.map(vehicle => {
      const modelAndTrim = vehicle.trim ? `${vehicle.model} ${vehicle.trim}` : vehicle.model
      return (
        <option
          value={modelAndTrim}
          key={Uuid()}
          data-handle={vehicle.handle}
        >
          {modelAndTrim}
        </option>
      )
    }
  )}
}

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE;
  });
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const filteredSortedHomeChargers = filterThenSortChargers(homeChargers, userPrefs);

  const renderAllIncentiveCounter = (isMobile = false) => {
    return (
        <>
          <ProductIncentiveCounter
            products={filteredSortedHomeChargers}
            productName={intl.formatMessage({ id: "homeChargers", defaultMessage: "home chargers"})}
            isMobile={isMobile}
          />
        </>
      );
  }

  const [makeOption, setMakeOption] = useState('')
  const [modelOption, setModelOption] = useState('')
  const [ev, setEv] = useState(null)

  if (!electricVehicles) {
    return null;
  }

  const onChangeMake = e => {
    const newMakeType = e.target.value
  
    setMakeOption(newMakeType)
  }

  const onChangeModelType = e => {
    const newModelType = e.target.value
    const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle
  
    if (newModelType === 'Select a Model') {
      setModelOption(newModelType)
      return
    } 
  
    const selectedVehicle = electricVehicles.find(vehicle => {
      return vehicle.handle === vehicleHandle
    })
  
    setModelOption(newModelType)
    setEv(selectedVehicle)
  }

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <HomeChargersFilterControls />
    </>
  );

  return (
    <>
    <section className="container" id="homeChargers">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h1>
            <FormattedMessage 
              id="homeChargers"
              defaultMessage="Home Chargers"
              description="Home Chargers"
            />
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-md-8 offset-md-2 text-center">
          <p className="lead text-center">
            <FormattedMessage 
              id="homeChargers.subText"
              defaultMessage="We've compiled a list of home chargers for you to consider. Select the make and model of the car you are considering to see an estimate of the charging time required by charger."
              description="Home Chargers Sub Text"
            />
          </p>
        </div>
      </div>

      <div className="row" style={{justifyContent: "center"}}>
        <div className="col-sm-3">
          <div className="form-group">
            <select
              className="form-control select-iid"
              value={makeOption}
              onChange={onChangeMake}
              aria-label="Select Make"
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}              
              </option>
              {vehicleMakeOptions(electricVehicles)}
            </select>
          </div>
        </div>
          
      <div className="col-sm-3">
          <div className="form-group">
            <select
              className="form-control select-iid"
              value={modelOption}
              onChange={onChangeModelType}
              aria-label="Select Model"
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
              </option>
              {vehicleModelOptions(electricVehicles, makeOption)}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderAllIncentiveCounter()}
        </div>
        <div className="col-md-6">
          <div className="pull-right">
            <ChargerSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={intl.formatMessage({ id: "evfilter", defaultMessage: "Filter"})}
            titleText="Refine Filters"
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="home-chargers-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <ChargerCatalog
            homeChargers={filteredSortedHomeChargers} selectedVehicle={ev}
          />
        </div>
      </div>
    </section>
    <Unsplash
          img={unsplashImage}
        />

    </>
  );
};

export default HomeChargers;

HomeChargers.propTypes = {
  electricVehicles: PropTypes.array,
  homechargers: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};