import React, {useState} from "react";
import PropTypes from "prop-types";
import Uuid from "../../utils/Uuid/Uuid"

import { useIntl } from 'react-intl';

const vehicleTypeOptions = (intl) => {
  const fuelTypes = ["PHEV", "BEV", "gas"]
  const fuelTypesSpelledOut = {
    PHEV: intl.formatMessage({ id: "vehicle.plugInHybrid", defaultMessage: "Plug in Hybrid"}), 
    BEV: intl.formatMessage({ id: "vehicle.allElectric", defaultMessage: "All Electric"}), 
    gas: intl.formatMessage({ id: "vehicle.gas", defaultMessage: "Gasoline"})
  }
  return fuelTypes.map(fuelType => {
    return (
      <option value={fuelType} key={fuelType}>
        {fuelTypesSpelledOut[fuelType]}
      </option>
    );
  });
};

const vehicleMakeOptions = (vehicles) => {

  let vehicleMakes = new Set()
  vehicles.forEach(vehicle => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make)
    }
  })

  vehicleMakes = [...vehicleMakes]
  
  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b)
  })

  return vehicleMakes.map(make => {
    return (
      <option
        value={make}
        key={make}
      >
        {make}
      </option>
    )
  })
}

const vehicleModelOptions = (vehicles, makeOption) => {

  if (makeOption) {
    vehicles = vehicles.filter(vehicle => {
      return vehicle.make === makeOption
    })
  
  vehicles.sort((a, b) => {
    return a.model.localeCompare(b.model)
  })
    
    return vehicles.map(vehicle => {
      const modelAndTrim = vehicle.trim ? `${vehicle.model} ${vehicle.trim}` : vehicle.model
      return (
        <option
          value={modelAndTrim}
          key={Uuid()}
          data-handle={vehicle.handle}
        >
          {modelAndTrim}
        </option>
      )
    }
  )}
}

const ComparedVehicle = ({ electricVehicles, gasolineVehicles, setVehicle }) => {

  const allVehicles = electricVehicles.concat(gasolineVehicles)

  const [evOptions, setEvOptions] = useState(allVehicles)
  const [fuelOption, setFuelOption] = useState('')
  const [makeOption, setMakeOption] = useState('')
  const [modelOption, setModelOption] = useState('')

  const intl = useIntl()

  if (allVehicles.length === 0) return null;

  const onChangeFuelType = e => {

    const newFuelType = e.target.value
    let filteredFuelVehicles

    if (newFuelType === "PHEV" || newFuelType === "BEV") {
      filteredFuelVehicles = electricVehicles.filter(vehicle => {
        return vehicle.fuel === newFuelType
      })
    } else if (newFuelType === "gas"){
      filteredFuelVehicles = gasolineVehicles.filter(vehicle => {
        return vehicle.fuel === newFuelType
      })
    } else {
      filteredFuelVehicles = allVehicles
    }

    setFuelOption(newFuelType)
    setMakeOption('')
    setModelOption('')
    setEvOptions(filteredFuelVehicles)
  };

const onChangeMake = e => {
  const newMakeType = e.target.value

  setMakeOption(newMakeType)
}

const onChangeModelType = e => {
  const newModelType = e.target.value
  const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle

  if (newModelType === 'Select a Model') {
    setModelOption(newModelType)
    return
  } 

  const selectedVehicle = evOptions.find(vehicle => {
    return vehicle.handle === vehicleHandle
  })

  setFuelOption(selectedVehicle.fuel)
  setModelOption(newModelType)
  setVehicle(selectedVehicle)
}

  return (
    <div className="ComparedVehicle">
      <div className="row" style={{justifyContent: "center"}}>
        <div className="col-sm-10">
          <div className="form-group">
            <select
              className="form-control select-dte"
              value={fuelOption}
              onChange={onChangeFuelType}
              aria-label="Select a Fuel Type"
            >
              <option  defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectFuel", defaultMessage: "Select a Fuel Type"})}
              </option>
              {vehicleTypeOptions(intl)}
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control select-dte"
              value={makeOption}
              onChange={onChangeMake}
              aria-label="Select a Make"
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
              </option>
              {vehicleMakeOptions(evOptions)}
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control select-dte"
              value={modelOption}
              onChange={onChangeModelType}
              aria-label="Select a Model"
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
              </option>
              {vehicleModelOptions(evOptions, makeOption)}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

ComparedVehicle.propTypes = {
  vehicle: PropTypes.object,
  electricVehicles: PropTypes.array,
  setVehicle: PropTypes.func
};

ComparedVehicle.defaultProps = {
  vehicle: {},
  electricVehicles: []
};

export default ComparedVehicle;
